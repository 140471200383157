import compose from "lodash/fp/compose";
import { domReady } from "../../utils";

const upcase = () =>
  $(".upCase").keypress(function (e) {
    const charInput = e.keyCode;

    if (charInput >= 97 && charInput <= 122) {
      if (!e.ctrlKey && !e.metaKey && !e.altKey) {
        let newChar = charInput - 32;
        let start = e.target.selectionStart;
        let end = e.target.selectionEnd;

        e.target.value =
          e.target.value.substring(0, start) +
          String.fromCharCode(newChar) +
          e.target.value.substring(end);
        e.target.setSelectionRange(start + 1, start + 1);
        e.preventDefault();
      }
    }
  });

const downcase = () =>
  $(".down").keypress(function (e) {
    e.target.value = e.target.value.toLowerCase();
    // if (charInput <= 97 && charInput >= 122) {
    //   if (!e.ctrlKey && !e.metaKey && !e.altKey) {
    //     let newChar = charInput - 32
    //     let start = e.target.selectionStart
    //     let end = e.target.selectionEnd

    //     e.target.value =
    //       e.target.value.substring(0, start) +
    //       String.fromCharCode(newChar) +
    //       e.target.value.substring(end)
    //     e.target.setSelectionRange(start + 1, start + 1)
    //     e.preventDefault()
    //   }
    // }
  });

export default domReady(compose(upcase, downcase));
