import "./components";
import "./initializers";
import "./template";
import "./vue";

import Dropzone from "dropzone";

import VMasker from "vanilla-masker";

const vmaskerCPF = () => {
  const cpfs = Array.from(document.querySelectorAll(".cpf"));

  if (cpfs) {
    cpfs.map((cpf) => {
      VMasker(cpf).maskPattern("999.999.999-99");
    });
  }
};

const vmaskerCNPJ = () => {
  const cnpjs = Array.from(document.querySelectorAll(".cnpj"));

  if (cnpjs) {
    cnpjs.map((cnpj) => {
      VMasker(cnpj).maskPattern("99.999.999/9999-99");
    });
  }
};

$(document).ready(function () {
  $(".kindSubscriptionConfig").on("click", function (e) {
    if (e.target.value == "weekly") {
      $(".daySubscriptionConfig").removeClass("d-none");
    } else {
      $(".daySubscriptionConfig").addClass("d-none");
    }
  });

  function camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  if ($("#next_button_step2") && $("#nameSeller")) {
    $("#next_button_step2").click((e) => {
      fbq("track", "Subscribe", {
        content_name: $("#nameSeller").text(),
        currency: "BRL",
        status: "CREATED",
      });
    });
  }
  window.camelize = camelize;

  Dropzone.autoDiscover = true;
  Dropzone.paramName = "image[file]";
  Dropzone.options.imageUpload = {
    maxFilesize: 10,
    acceptedFiles: ".jpeg,.jpg,.png,.gif",
  };
  Dropzone.discover();

  window.copyBarcode = () => {
    let $temp = $("<input>");
    $("body").append($temp);
    $temp.val($("#barcode").text()).select();
    document.execCommand("copy");
    $(".copyBarcodeLink").html("Link Copiado");

    $temp.remove();
  };

  $(".copy-button").on("click", function () {
    var textArea = document.createElement("textarea");
    textArea.value = $(this).attr("data-link");
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    $(this).html("Link Copiado");
    textArea.remove();
    setTimeout(() => {
      $(this).html("Copiar Link");
    }, 2000);
  });

  $(".copy-button-custom").on("click", function () {
    var textArea = document.createElement("textarea");
    textArea.value = $(this).attr("data-link");
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    $("#text-copy-button-custom").text("Copiado");
    textArea.remove();
    setTimeout(() => {
      $("#text-copy-button-custom").text("Copiar linha digitável");
    }, 2000);
  });

  function readURL(input, id) {
    if (input.files && input.files[0]) {
      let reader = new FileReader();

      reader.onload = function (e) {
        // console.log("#photo-field-" + id);
        $("#photo-field-" + id).attr("src", e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  $("#store_store_contact_attributes_kind").change(function () {
    if ($(this).val() == "pj") {
      $("#store_store_contact_attributes_document").removeClass("cpf");
      $("#store_store_contact_attributes_document").addClass("cnpj");

      $("#label_document_store_contact").html("CNPJ");
      $("#label_name_store_contact").html("Razão Social");

      vmaskerCNPJ();
    } else {
      $("#store_store_contact_attributes_document").addClass("cpf");
      $("#store_store_contact_attributes_document").removeClass("cnpj");
      $("#label_document_store_contact").html("CPF");
      $("#label_name_store_contact").html("Nome Completo");

      vmaskerCPF();
    }
  });

  $(".photo-field-input").change(function () {
    const id = $(this).attr("id").replace("photo-field-input-", "");
    readURL(this, id);
  });

  document.addEventListener("turbo:before-stream-render", function (e) {
    $(".star-icon").on("click", function () {
      let id = this.id;
      let group = id.split("_")[id.split("_").length - 1];
      $(".stars-icon-" + group).each((index, element) => {
        $(element).addClass("mdi-star-outline");
        $(element).removeClass("mdi-star");
      });
      $(".stars-icon-" + group).each((index, element) => {
        $(element).addClass("mdi-star");
        $(element).removeClass("mdi-star-outline");
        if (element.id == id) {
          $("#social_proof_testimonials_attributes_" + group + "_stars").val(
            index + 1
          );
          return false;
        }
      });
    });

    $(".star-icon").on("mouseover", function () {
      let id = this.id;
      let group = id.split("_")[id.split("_").length - 1];
      // let group = id.slice(id.length-1)
      $(".stars-icon-" + group).each((index, element) => {
        // console.log(element)
        $(element).addClass("mdi-star-outline");
        $(element).removeClass("mdi-star");
      });
      $(".stars-icon-" + group).each((index, element) => {
        $(element).addClass("mdi-star");
        $(element).removeClass("mdi-star-outline");
        if (element.id == id) {
          $("#social_proof_testimonials_attributes_" + group + "_stars").val(
            index + 1
          );
          return false;
        }
      });
    });
  });

  window.verifyProductVirtual = function verifyProductVirtual() {
    let radioVirtualValue = $("input[name='product[virtual]']:checked").val();
    // console.log("ENCONTRANDO TUDO");
    // console.log(radioVirtualValue);
    if (radioVirtualValue) {
      if (radioVirtualValue == "true") {
        // console.log("HIDE ALL");
        $("#dimensionsBlock").hide("fast");
        $("#dimensionsBlockModal").hide("fast");
        // $("#skuBlock").hide("fast");
        // $("#skuBlockModal").hide("fast");
        // $(".notVirtualField").hide("fast");
      } else {
        // $("#skuBlock").show("fast");
        // $("#skuBlockModal").show("fast");
        // $(".notVirtualField").show("fast");
        $("#dimensionsBlock").show("fast");
        $("#dimensionsBlockModal").show("fast");
      }
    }
  };

  window.verifyProductVirtual();

  $("input[name='product[virtual]']").click(function () {
    window.verifyProductVirtual();
  });

  let radioValue = $("input[name='product[multiple]']:checked").val();

  if (radioValue) {
    if (radioValue == "true") {
      $("#variations_item").html("");
      $(".grids").show("fast");
      $(".blockVariations").hide("fast");
    } else {
      $(".grids").hide("fast");

      if ($(".nested-fields").length <= 0) {
        $("#add_variation")[0].click();
      }
    }

    // **************************************************
    // A FUNÇÃO ABAIXO COMENTADA FOI MIGRADA PARA:
    // app/javascripts/store/products_controller.js
    // **************************************************

    // $("input[name='product[multiple]']").click(function () {
    //   let radioValue = $("input[name='product[multiple]']:checked").val();

    //   if (radioValue == "true") {
    //     $("#variations_item").html("");
    //     $(".grids").show("fast");
    //     $(".blockVariations").hide("fast");

    //   } else {
    //     $("#product_grid_ids").prop("selectedIndex", -1);
    //     $(".grids").hide("fast");
    //     $(".blockVariations").show("fast");
    //     $("#add_variation")[0].click();

    //   }

    //   window.verifyProductVirtual();
    // });
  }

  $(".markPixelCode").change(function (e) {
    if (e.target.checked) {
      $(".markPixelCodeTextArea").removeClass("d-none");
    } else {
      $(".markPixelCodeTextArea").addClass("d-none");
    }
  });

  $(".markGtmCode").change(function (e) {
    if (e.target.checked) {
      $(".markGtmCodeTextArea").removeClass("d-none");
    } else {
      $(".markGtmCodeTextArea").addClass("d-none");
    }
  });

  $(".state_search").change(function () {
    let id_state = $(".state_search").val();
    $.get("/list/cities/" + id_state, function (data) {
      let x;
      $("#city").html("");
      $("#city").append("<option value> Selecione a cidade </option >");
      for (x in data) {
        let option =
          '<option value ="' + data[x].id + '">' + data[x].name + "</option >";
        $("#city").append(option);
      }
    });
  });

  $(".client_search").change(function () {
    let client_id = $(".client_search").val();
    $.get("/list/stores/" + client_id, function (data) {
      let x;
      $("#q_stores_name_eq").html("");
      $("#q_stores_name_eq").append(
        "<option value> Selecione a loja </option>"
      );
      for (x in data) {
        let option =
          '<option value ="' + data[x] + '">' + data[x] + "</option >";
        $("#q_stores_name_eq").append(option);
      }
    });
  });

  $(".cep_search").keyup(function () {
    const cep = $(this).val();
    const filter_cep = cep.replace(/\D/g, "");
    $(this).css({ "border-color": "rgb(206, 212, 218)" });
    $(".cep_error").remove();
    if (filter_cep.length == 8) {
      $.get(
        `https://viacep.com.br/ws/${filter_cep}/json/`,
        {},
        function (data) {
          if (!data.erro) {
            $("#store_addresses_attributes_0_zip_code_valid").val(true);
            $(".neighborhood_search").val(data.bairro);
            $(".street_search").val(data.logradouro);
            let state_id = $(
              `.state_search option:contains(- ${data.uf})`
            ).val();
            $(".state_search").val(state_id);
            $(".city_search").val("");
            $(".city_search").html("");
            $.get(`/list/cities/${state_id}`, {}, function (cities_data) {
              $(".city_search").append(
                '<option value="">Selecione a Cidade</option>'
              );
              cities_data.forEach(function (city) {
                var newOption = new Option(city.name, city.id, false, false);
                // Append it to the select
                $(".city_search").append(newOption);
                if (city.name == data.localidade) {
                  $(".city_search").val(city.id);
                }
              });
            });
            $(".save-client").prop("disabled", false)
          } else {
            //cep não encontrado.
            limpa_formulario_cep();
            $(".cep_search").css({ "border-color": "rgb(255, 0, 0)" });
            $("#cep_div").append(
              "<span class='cep_error small text-danger'>CEP não encontrado</span>"
            );
            $("#store_addresses_attributes_0_zip_code_valid").val(false);
            $(".save-client").prop("disabled", true)
          }
        }
      );
    } else {
      //cep é inválido.
      limpa_formulario_cep();
      $(".cep_search").css({ "border-color": "rgb(255, 0, 0)" });
      $("#cep_div").append(
        "<span class='cep_error small text-danger'>CEP inválido</span>"
      );
      $("#store_addresses_attributes_0_zip_code_valid").val(false);
      $(".save-client").prop("disabled", true)
    }
  });

  function limpa_formulario_cep() {
    // Limpa valores do formulário de cep.
    $(".cep_error").remove();
    $(".neighborhood_search").val("");
    $(".street_search").val("");
    $(".state_search").val("");
    $(".city_search").val("");
    $(".complement_search").val("");
    $(".number_search").val("");
  }
});

$(document).on("cocoon:insert");

if (
  $("#suspendedModal") &&
  $("#suspendedModal").attr("data-value") == "suspended"
) {
  $.get(
    "/store/subscriptions/open_modal_suspended",
    {},
    function () { },
    "script"
  );
}
