$(document).on('click', '.card-input', function () {
  let card = $(this).closest('.card-input')
  $('.card-input').removeClass('card-input-selected')
  $(card).addClass('card-input-selected')
  $('#subscription_card_id').val(card.data('card-id'))
});

$(document).on('click', '.card-option', function () {
  let card = $(this).closest('.card-option')
  $('.card-option').removeClass('card-option-selected')
  $(card).addClass('card-option-selected')
  let card_type = card.data('type')
  let new_card_input = $("#new_card input")
  let existing_card_id_input = $("#subscription_card_id")
  
  if(card_type === "existing_card") {
    $("#existing_cards").removeClass('d-none')
    $("#new_card").addClass('d-none')
    $('.card-input').first().click()
    new_card_input.prop('required', false)
    new_card_input.prop('disabled', true)
    existing_card_id_input.prop('disabled', false)
  } else {
    $("#existing_cards").addClass('d-none')
    $("#new_card").removeClass('d-none')
    $('#subscription_card_id').val(null)
    new_card_input.prop('required', true)
    new_card_input.prop('disabled', false)
    existing_card_id_input.prop('disabled', true)
  }
});
