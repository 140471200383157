import compose from "lodash/fp/compose";
import { domReady } from "../../utils";

const togglePassword = () => {
  const targetTogglePassword = document.querySelector("#togglePasswordBtn");
  const passwordField = document.querySelector("#user_password");
  const icon = document.querySelector("#togglePasswordBtn .mdi");

  if (targetTogglePassword) {
    targetTogglePassword.addEventListener("click", function (event) {
      event.preventDefault();

      if (passwordField.getAttribute("type") == "password") {
        passwordField.setAttribute("type", "text");
        icon.classList.remove("mdi-eye-off");
        icon.classList.add("mdi-eye");
      } else {
        passwordField.setAttribute("type", "password");
        icon.classList.remove("mdi-eye");
        icon.classList.add("mdi-eye-off");
      }
    });
  }
};

const togglePasswordConfirmation = () => {
  const targetTogglePasswordConfirmation = document.querySelector(
    "#togglePasswordConfirmationBtn"
  );
  const passwordField = document.querySelector("#user_password_confirmation");
  const icon = document.querySelector("#togglePasswordConfirmationBtn .mdi");

  if (targetTogglePasswordConfirmation) {
    targetTogglePasswordConfirmation.addEventListener(
      "click",
      function (event) {
        event.preventDefault();

        if (passwordField.getAttribute("type") == "password") {
          passwordField.setAttribute("type", "text");
          icon.classList.remove("mdi-eye-off");
          icon.classList.add("mdi-eye");
        } else {
          passwordField.setAttribute("type", "password");
          icon.classList.remove("mdi-eye");
          icon.classList.add("mdi-eye-off");
        }
      }
    );
  }
};

const toggleCurrentPassword = () => {
  const targetToggleCurrentPassword = document.querySelector(
    "#toggleCurrentPasswordBtn"
  );
  const passwordField = document.querySelector("#user_current_password");
  const icon = document.querySelector("#toggleCurrentPasswordBtn .mdi");

  if (targetToggleCurrentPassword) {
    targetToggleCurrentPassword.addEventListener("click", function (event) {
      event.preventDefault();

      if (passwordField.getAttribute("type") == "password") {
        passwordField.setAttribute("type", "text");
        icon.classList.remove("mdi-eye-off");
        icon.classList.add("mdi-eye");
      } else {
        passwordField.setAttribute("type", "password");
        icon.classList.remove("mdi-eye");
        icon.classList.add("mdi-eye-off");
      }
    });
  }
};

const changeTerms = () => {
  if ($("#terms_user").is(":checked")) {
    $("#next_button_step1").attr("disabled", false);
  } else {
    $("#next_button_step1").attr("disabled", true);
  }

  $("#terms_user").change(function () {
    if ($(this).is(":checked")) {
      $("#next_button_step1").attr("disabled", false);
    } else {
      $("#next_button_step1").attr("disabled", true);
    }
  });
};

const submitFormAddressSeller = () => {
  $("#form_address_seller").on("submit", function (ev) {
    ev.preventDefault();
    // console.log("PAROU")
    let elements = document.querySelectorAll(".overlay-submit-form");
    for (let element of elements) {
      element.classList.remove("d-none");
      element.classList.add("d-flex");
    }

    setTimeout(() => {
      $("#form_address_seller").unbind("submit").submit();
    }, 2000);
  });
};

export default domReady(
  compose(
    togglePassword,
    togglePasswordConfirmation,
    toggleCurrentPassword,
    changeTerms,
    submitFormAddressSeller
  )
);
